import { useState } from "react";
import { motion } from "framer-motion";

export function Example() {
  const [dragging, setDragging] = useState(false);

  return (
    <motion.div
      style={{
        width: "100vw",
        height: "100vh",
        backgroundColor: "#04f",
        overflow: "hidden",
        display: "flex",
        placeItems: "center",
        placeContent: "center"
      }}
      animate={{ backgroundColor: dragging ? "#a0f" : "#04f" }}
      transition={{ duration: 0.5 }}
    >
      <motion.div
        style={{
          width: 150,
          height: 150,
          borderRadius: 30,
          backgroundColor: "#fff",
          cursor: dragging ? "grabbing" : "grab"
        }}
        // Dragging
        drag
        dragConstraints={{ top: -140, right: 140, bottom: 140, left: -140 }}
        // Animation
        animate={{ scale: dragging ? 1 : 0.8 }}
        onDragStart={() => setDragging(true)}
        onDragEnd={() => setDragging(false)}
      />
    </motion.div>
  );
}
